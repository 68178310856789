import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import aboutImage from './images/about1.jpg';

function About() {
    return (
        <div>
            <Navbar />

            <section className="page-title bg-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block text-center">
                                <span className="text-white">About Us</span>
                                <h1 className="text-capitalize mb-5 text-lg">About Us</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="aboutSec" className="section about">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-img">
                                <img src={aboutImage} alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-content pl-4 mt-4 mt-lg-0">
                                <h2 className="title-color">CA Electrical Solutions</h2>
                                <p>Our trained and highly experienced technicians specialize in installing, repairing, and
                                    servicing EVSE (Electric Vehicle Supply Equipment) for a wide array of manufacturers,
                                    including Toyota, Nissan, Ford, Tesla, and more. Our EVSE services include:</p>
                                <ul>
                                    <li>Professional EV charging system installation by licensed technicians</li>
                                    <li>Inspection of all equipment before and after installation</li>
                                    <li>Installing the equipment according to the manufacturer’s specifications</li>
                                    <li>Upgrading the electrical system, including adding a dedicated circuit</li>
                                    <li>Ensuring that the EV charging system is complaint with all local and national codes and
                                        requirements</li>
                                    <li>Providing the safety and warranty information of the system to the client.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <p>Moreover, our expert technicians can also service existing EV charging systems. If you have
                        safety concerns about your EVSE, or if your vehicle is charging too slowly or not charging it
                        all, you can on our expert technicians to identify and address the problem.</p>
                </div>
            </section> 
            
            <Footer />
        </div>
    );
}

export default About;
