import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ServicesImg from './serviceImg';

function Services() {
    return (
        <div>
            <Navbar />
            <section className="page-title bg-1">
                <div className="container text-center">
                    <span className="text-white">Our services</span>
                    <h1 className="text-capitalize mb-5 text-lg text-white">What We Do</h1>
                </div>
            </section>

            <section className="section service-2">
                <div className="container">
                    <div className="row">
                        <ServicesImg />
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default Services;
