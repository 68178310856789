import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import s1 from './images/services/Air-Conditioner-Power.jpg';
import s2 from './images/services/Bathroom-exhaust.jpg';
import s3 from './images/services/EV-charger.jpg';
import s4 from './images/services/Knob-tube-wiring.jpg';
import s5 from './images/services/Led-lightning.jpg';
import s6 from './images/services/Other-services.jpg';
import s7 from './images/services/Electrical-Panel.jpg';

const services = [
    { image: s1, title: 'Air Conditioner Power', description: "Before deciding to upgrade your home's heating and cooling system, reach out to Brilliant Electric to inspect and troubleshoot the electrical circuits that power your HVAC units. Over time, dedicated circuits for heating and air conditioning can wear down and may need replacement. Brilliant Electric can collaborate with your HVAC contractor to install updated electrical connections for your furnace or air conditioner." },
    { image: s2, title: 'Bathroom Exhaust Fan', description: "An underperforming exhaust fan can leave your bathroom steamy and foggy after every shower. The excess moisture not only creates an uncomfortable environment but can also lead to mold growth and damage to your bathroom walls. Installing a new exhaust fan is a simple fix that will eliminate moisture and mold, leaving your bathroom fresh and breathable." },
    { image: s3, title: 'Electric Vehicle Charger', description: "An electric vehicle can help lower your carbon footprint and reduce transportation costs. While EVs may have limited range and longer charging times, installing a dedicated charger at your home or business provides the convenience and peace of mind you need for seamless, hassle-free charging." },
    { image: s4, title: 'Knob Tube Wiring', description: "Knob-and-tube wiring consists of two wires insulated with rubber, supported by ceramic knobs and protected by tubes. If you can see any exposed wiring in your home without touching it, check to see if it matches this description. Homes built before 1950 often still have knob-and-tube wiring, so if you spot it, it's a sign your home may be of that age." },
    { image: s5, title: 'LED Lighting', description: "Light-Emitting Diode (LED) lighting offers a durable, energy-efficient, and environmentally friendly alternative to High Intensity Discharge (HID) lighting. Key benefits include cost savings, long-lasting durability, eco-friendliness, consistent illumination, quiet operation, and enhanced health and safety." },
    { image: s7, title: 'Electrical Panel', description: "Your electric service panel controls the flow of electricity throughout your home. When a circuit is overloaded, the service panel automatically trips a breaker to protect against potential fire, shock, or damage. This panel is housed within the breaker box, serving as a safety feature to regulate and manage your home's electrical system." },
    { image: s6, title: 'Commercial Services', description: "Brilliant Electric offers a full range of commercial electrical services designed to meet the unique needs of your business. Whether you need complex wiring installations, electrical system upgrades, or specialized HVAC circuit troubleshooting, our experienced team delivers reliable, safe, and efficient solutions for any commercial setting. We work with minimal disruption to your operations, ensuring seamless project completion. Get in touch with us today for a free estimate and let us provide the expert electrical services your business deserves." },
    { image: s7, title: 'Property Management', description: "Your property's electrical service panel is essential for managing the flow of electricity throughout the building. When a circuit becomes overloaded, the panel automatically trips a breaker, safeguarding against potential fire, electrical shock, or property damage. This panel is located within the breaker box, acting as a crucial safety feature to regulate and protect the building’s electrical system for tenants and owners." },
    { image: s6, title: 'Other Services', description: "Brilliant Electric is a full-service electrical company offering expert solutions for all your electrical needs, from wiring upgrades to HVAC circuit troubleshooting. Our team is committed to delivering high-quality, reliable services that ensure safety and efficiency in your home or business. Contact us today for a free, no-obligation estimate and let us help you with your next electrical project." },
];

function ServicesImg() {
    return (
        <section className="section service-2">
            <div className="container">
                <div className="row">
                    {services.map((service, index) => (
                        <div key={index} className="col-lg-4 col-md-6 col-sm-6">
                            <div className="service-block mb-5">
                                <img src={service.image} alt="" className="img-fluid" />
                                <div className="content">
                                    <h4 className="mt-4 mb-2 title-color">{service.title}</h4>
                                    <p className="mb-4">{service.description}</p>
                                </div>
                                <div className='text-center'>
                                    <Link to="/contact" className="btn btn-main btn-icon btn-round-full">
                                        Get Quote <FontAwesomeIcon icon={faChevronRight} />
                                    </Link> 
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default ServicesImg;
