import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import GalleryImg from './GalleryImg';
import ServicesImg from './serviceImg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import aboutImage from './images/about1.jpg';

import './css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Home = () => {
    return (
        <div className="">
            <Navbar />

            <section className="banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-xl-7">
                            <div className="block">
                                <div className="divider mb-3"></div>
                                <h1 className="mb-3 mt-3">CA Electrical Solutions</h1>

                                <p className="mb-4 pr-5">Here at <b>CA Electrical Solutions</b>, we believe that the best place to charge your EV
                                    overnight is your own home, which is why we offer <b>CA Electrical Solutions</b>
                                    all across the Bay Area.</p>
                                <Link to="/contact" className="btn btn-main btn-icon btn-round-full">
                                    Get Quote <FontAwesomeIcon icon={faChevronRight} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="aboutSec" className="section about">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-img">
                                <img src={aboutImage} alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-content pl-4 mt-4 mt-lg-0">
                                <h2 className="title-color">About CA Electrical Solutions</h2>
                                <p>Our trained and highly experienced technicians specialize in installing, repairing, and
                                    servicing EVSE (Electric Vehicle Supply Equipment) for a wide array of manufacturers,
                                    including Toyota, Nissan, Ford, Tesla, and more. Our EVSE services include:</p>
                                <ul>
                                    <li>Professional EV charging system installation by licensed technicians</li>
                                    <li>Inspection of all equipment before and after installation</li>
                                    <li>Installing the equipment according to the manufacturer’s specifications</li>
                                    <li>Upgrading the electrical system, including adding a dedicated circuit</li>
                                    <li>Ensuring that the EV charging system is complaint with all local and national codes and
                                        requirements</li>
                                    <li>Providing the safety and warranty information of the system to the client.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <p>Moreover, our expert technicians can also service existing EV charging systems. If you have
                        safety concerns about your EVSE, or if your vehicle is charging too slowly or not charging it
                        all, you can on our expert technicians to identify and address the problem.</p>
                </div>
            </section>

            <section id="serviceSec" className="section service gray-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 text-center">
                            <div className="section-title">
                                <h2>The Bay Area’s Best Electrical Contractor</h2>
                                <div className="divider mx-auto my-4"></div>
                                <p>Being one of the bay area’s leading electrical contractors, we cater to the needs of both
                                    commercial and residential clients. The comfort and safety of our clients is our utmost
                                    priority, which is why we take a proactive approach when it comes to responding to emergency
                                    calls. Furthermore, at <b>CA Electrical Solutions</b>s, accountability and transparency are two of our
                                    business’s core values, which is why we’re straightforward and upfront when it comes to
                                    process. When you work with us, you don’t have to worry about any hidden charges.</p>
                                <p>Whether you simply want a couple of light fixtures replaced or it’s a complex rewiring job,
                                    no job is too big or too small for us. In addition to being highly skilled and experienced,
                                    our technicians are equipped with the latest, cutting-edge tools and equipment. Regardless
                                    of the scope and size of your project, you can rely on us to get the job done safely,
                                    efficiently, on time, and within budget. If you want to schedule an appointment, or want to
                                    learn more, about our company, get in touch with us today. </p>
                            </div>
                            <hr />
                            <div className="section-title">
                                <h4><b>CA Electrical Solutions</b> is a Licensed Commercial and Residential Electrical Contractor Serving the
                                    Bay Area</h4>
                                <div className="divider mx-auto my-4"></div>
                                <p>Our services are unparalleled and we strive to give you the best electrical service without
                                    sacrificing quality. We set high standards and we strive to meet those standards every day.
                                </p>
                                <br />
                                <div className="btn-container ">
                                    <Link to="/contact" className="btn btn-main btn-icon btn-round-full">
                                        Get Quote <FontAwesomeIcon icon={faChevronRight} />
                                    </Link>
                                    <Link to="tel:(510) 779-4422" className="btn btn-main btn-icon btn-round-full">
                                        Call Now (510) 779-4422 <FontAwesomeIcon icon={faChevronRight} />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section service">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 text-center">
                            <div className="section-title">
                                <h2>Our Services</h2>
                                <div className="divider mx-auto my-4"></div>
                                <ServicesImg />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section gray-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 text-center">
                            <div className="section-title">
                                <h2>Gallery</h2>
                                <div className="divider mx-auto my-4"></div>
                                <GalleryImg />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section id="enquirySec" className="section appoinment">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="appoinment-wrap mt-5 mt-lg-0">
                                <h2 className="mb-2 title-color text-center">Request a Free Estimate</h2>
                                <div className="divider mx-auto my-4"></div>
                                <form id="#" className="appoinment-form" method="post" action="#">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>Name*</label>
                                                <input id="name" type="text" className="form-control" placeholder="Full Name.." />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>Email*</label>
                                                <input id="name" type="email" className="form-control" placeholder="Email.." />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>Phone Number*</label>
                                                <input id="phone" type="Number" className="form-control"
                                                    placeholder="Phone Number.." />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>Zip Code*</label>
                                                <input id="phone" type="Number" className="form-control" placeholder="Zip Code*" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>Tentative Start Date</label>
                                                <input id="phone" type="date" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>Services*</label>
                                                <select className="form-control" id="exampleFormControlSelect1">
                                                    <option value="Additional Circuits">Additional Circuits</option>
                                                    <option value="Commercial & Residential">Commercial & Residential</option>
                                                    <option value="Conduit Work">Conduit Work</option>
                                                    <option value="Electrical Panel Upgrades">Electrical Panel Upgrades
                                                    </option>
                                                    <option value="EV Chargers">EV Chargers</option>
                                                    <option value="Existing Remodel Work">Existing Remodel Work</option>
                                                    <option value="New Construction">New Construction</option>
                                                    <option value="Lighting Controls">Lighting Controls</option>
                                                    <option value="Recessed Lighting">Recessed Lighting</option>
                                                    <option value="Tenant Improvements">Tenant Improvements</option>
                                                    <option value="Troubleshooting & Repair">Troubleshooting &amp;
                                                        Repair</option>
                                                    <option value="Whole House Rewires">Whole House Rewires</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label>Message</label>
                                        <textarea name="message" id="message" className="form-control" rows="4"
                                            placeholder="Your Message"></textarea>
                                    </div>
                                    <div className="text-center">
                                        <Link to="/contact" className="btn btn-main btn-icon btn-round-full">
                                            Get Quote <FontAwesomeIcon icon={faChevronRight} />
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <Footer />
        </div>
    );
};

export default Home;
