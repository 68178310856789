import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import GalleryImg from './GalleryImg';

function Gallery() {
    return (
        <div>
            <Navbar />

            <section className="page-title bg-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block text-center">
                                <span className="text-white">Gallery</span>
                                <h1 className="text-capitalize mb-5 text-lg">Gallery</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container">
                    <div className='row'>
                        <GalleryImg />
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default Gallery;