import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faHome, faBars } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };
    return (
        <header>
            <div className="header-top-bar">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-8">
                            <ul className="top-bar-info list-inline-item pl-0 mb-0">
                                <li className="list-inline-item">
                                    <Link to="mailto:caelectric911@gmail.com">
                                        <FontAwesomeIcon icon={faEnvelope} /> caelectric911@gmail.com
                                    </Link>
                                </li>
                                <li className="list-inline-item"><FontAwesomeIcon icon={faHome} /> 4210, SATURN WAY, UNION CITY, CA, 94587</li>
                            </ul>
                        </div>
                        <div className="col-lg-4">
                            <div className="text-lg-right top-right-bar mt-2 mt-lg-0">
                                <Link to="tel:(510)-779-4422">
                                    <span><FontAwesomeIcon icon={faPhone} /> Call Now : </span>
                                    <span className="h4"> (510)-779-4422</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg navigation" id="navbar">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} alt="" className="img-fluid" />
                    </Link>

                    <button className="navbar-toggler" onClick={toggleNavbar}>
                        <FontAwesomeIcon icon={faBars} />
                    </button>

                    {/* Collapse the navbar content based on isOpen state */}
                    <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarmain">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item active"><Link className="nav-link" to="/">Home</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/about">About</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/services">Services</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/gallery">Gallery</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Navbar;
