import React from 'react'; 

import g1 from './images/gallery/1.jpg'
import g2 from './images/gallery/2.jpg'
import g3 from './images/gallery/3.jpg'
import g4 from './images/gallery/4.jpg'
import g5 from './images/gallery/5.jpg'
import g6 from './images/gallery/6.jpg'
import g7 from './images/gallery/7.jpg'
import g8 from './images/gallery/8.jpg'
import g9 from './images/gallery/9.jpg'
import g10 from './images/gallery/10.jpg'
import g11 from './images/gallery/11.jpg'
import g12 from './images/gallery/12.jpg'
const images = [g1, g2, g3, g4, g5, g6, g7, g8, g9, g10, g11, g12];

function GalleryImg() {
    return ( 
        <div className="section">
            <div className="container">
                <div className="row">
                    {images.map((image, index) => (
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={index}>
                            <img src={image} alt={`Gallery image ${index + 1}`} className="img-fluid" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default GalleryImg;