import React from 'react';

function Map() {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3161.6819266245743!2d-122.07252322513568!3d37.58610692300732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f95ef18ae7715%3A0x9526aa490eddba16!2s4210%20Saturn%20Way%2C%20Union%20City%2C%20CA%2094587%2C%20USA!5e0!3m2!1sen!2sin!4v1730306087181!5m2!1sen!2sin"
        width="100%"
        height="200"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        title="Google Maps"
      ></iframe>
    </div>
  );
}

export default Map;
