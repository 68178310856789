import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Map from './components/Map';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faHome } from '@fortawesome/free-solid-svg-icons';

function Contact() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_tzcuun9', 'template_j7t30sr', form.current, 'rJNK0ksZE4RAY84v5')
            .then((result) => {
                console.log(result.text);
                alert("Your message was sent successfully!");
                form.current.querySelectorAll('input, textarea').forEach(input => input.value = '');
            }, (error) => {
                console.log(error.text);
                alert("There was an error sending your message. Please try again.");
            });
    };

    return (
        <div>
            <Navbar />

            <section className="page-title bg-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block text-center">
                                <span className="text-white">Contact Us</span>
                                <h1 className="text-capitalize mb-5 text-lg">Get in Touch</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section contact-info pb-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            <div className="contact-block mb-4 mb-lg-0">
                                <FontAwesomeIcon icon={faPhone} />
                                <h5>Call Us</h5>
                                (510) 779-4422
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            <div className="contact-block mb-4 mb-lg-0">
                                <FontAwesomeIcon icon={faEnvelope} />
                                <h5>Email Us</h5>
                                caelectric911@gmail.com
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            <div className="contact-block mb-4 mb-lg-0">
                                <FontAwesomeIcon icon={faHome} />
                                <h5>Location</h5>
                                4210, SATURN WAY, UNION CITY, CA, 94587
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-form-wrap section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section-title text-center">
                                <h2 className="text-md mb-2">Contact us</h2>
                                <div className="divider mx-auto my-4"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <form ref={form} onSubmit={sendEmail} className="contact__form">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input name="from_name" type="text" className="form-control" placeholder="Your Full Name" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input name="from_email" type="email" className="form-control" placeholder="Your Email Address" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input name="subject" type="text" className="form-control" placeholder="Your Query Topic" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input name="phone" type="text" className="form-control" placeholder="Your Phone Number" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group-2 mb-4">
                                    <textarea name="message" className="form-control" rows="8" placeholder="Your Message" required></textarea>
                                </div>
                                <div className="text-center">
                                    <button className="btn btn-main btn-round-full" name="submit" type="submit">Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <Map />
            <Footer />
        </div>
    );
}

export default Contact;
